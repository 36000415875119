<template>
  <div class="cat-placeholder">
    <div class="container">
      <div class="title-container loading-placeholder" v-if="showTitlePlace"></div>


      <div  v-for="(i, index) in new Array(7) " :class="{
                'skeleton': true,
                'loading-placeholder': true,
                'skeleton-portraits': screen.displayType === 'PORTRAIT',
                'skeleton-landscape': screen.displayType == 'LANDSCAPE'
              }"></div>



      <div v-for="(i, index) in cardItems" :key="index">
        <div class="skeleton-container ">
          <div  v-for="(i, index) in new Array(7) " :class="{
                'skeleton': true,
                'loading-placeholder': true,
                'skeleton-portraits': screen.displayType === 'PORTRAIT',
                'skeleton-landscape': screen.displayType == 'LANDSCAPE'
              }"></div>
        </div>
        <div class="mobile-skeleton-container">
          <div class="skeleton-items">
            <div class="skeleton loading-placeholder"></div>
            <div class="skeleton loading-placeholder"></div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  props: {
    screen: {
      type: [Array, Object],
    },
    id: {
      type: Number,
    },
    screenName: {
      type: String,
    }
  },
  data() {
    return {
      showTitlePlace: true,
      cardItems: 1
    };
  },
  mounted() {
    console.log(this.screen, "===")
    if (this.$route.name == "detailComponent") {
      this.showTitlePlace = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_components.scss";

.loading-placeholder {
    position: relative;
    background-color: #e2e2e2;
    &::after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
        animation: loading 1.5s infinite;
    }
}

@keyframes loading-placeholder {
    100% {
        transform: translateX(100%);
    }
}


.title-container {
  margin: 10px 5px;
  height: 20px;
  border-radius: 8px;
  width: 24%;
  background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
  overflow: hidden;
}
.skeleton-container {
  display: flex;
  .skeleton {
    margin: 5px;
    border-radius: 8px;
    background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
    overflow: hidden;
    flex: 1 0 auto;
  }
  .skeleton-portraits {
    width: 13rem;
    height: 18rem;
   }
   .skeleton-landscape { 
    width: 15rem;
    height: 140px;
   }
}
.mobile-skeleton-container {
  display: none;
  .skeleton-items {
    display: flex;
    .skeleton {
      width: 50%;
      height: 264px;
      margin: 5px;
      border-radius: 8px;
      background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: 599px) {
  .skeleton-container {
    display: none;
  }
  .mobile-skeleton-container {
    display: block;
  }
}
</style>
