var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cat-placeholder"},[_c('div',{staticClass:"container"},[(_vm.showTitlePlace)?_c('div',{staticClass:"title-container loading-placeholder"}):_vm._e(),_vm._l((new Array(7)),function(i,index){return _c('div',{class:{
              'skeleton': true,
              'loading-placeholder': true,
              'skeleton-portraits': _vm.screen.displayType === 'PORTRAIT',
              'skeleton-landscape': _vm.screen.displayType == 'LANDSCAPE'
            }})}),_vm._l((_vm.cardItems),function(i,index){return _c('div',{key:index},[_c('div',{staticClass:"skeleton-container "},_vm._l((new Array(7)),function(i,index){return _c('div',{class:{
              'skeleton': true,
              'loading-placeholder': true,
              'skeleton-portraits': _vm.screen.displayType === 'PORTRAIT',
              'skeleton-landscape': _vm.screen.displayType == 'LANDSCAPE'
            }})}),0),_vm._m(0,true)])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-skeleton-container"},[_c('div',{staticClass:"skeleton-items"},[_c('div',{staticClass:"skeleton loading-placeholder"}),_c('div',{staticClass:"skeleton loading-placeholder"})])])}]

export { render, staticRenderFns }